@use './assets/scss/breakpoints-mixins' as viewport;
@import './assets/variables.scss';
@import './assets/fonts.scss';
@import './assets/theme.scss';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.min.css';
@import './assets/scss/primeng-override';
@import './assets/scss/custom-styles';

html {
	height: 100%;
}

body {
	padding: 0;
	overflow-x: hidden;
	margin: 0;
	min-height: 100%;
	background-color: $contentBgColor;
	font-family: $font-family !important;
}

.layout-content {
	&.panel-sidecontent-active {
		.side-panel-content {
			display: inline !important;
		}

		// .main-content{
		//     margin-left: $sidePanelContentWidth !important;
		// }
	}
}

.panel-footer {
	background: linear-gradient(90.12deg, rgba(206, 220, 227, 0) 0%, $linde-blue-gray-30 100%);
}

p,
h2,
h1,
h3 {
	font-family: $font-family;
	color: $linde-blue-100;
}

h4 {
	font-family: $font-family;
	color: $linde-blue-100;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
}

.border {
	border: 1px solid $linde-blue-gray-70;
}

.selected-item {
	border: 1px solid $linde-cyan;
	background-color: $linde-blue-gray-20;
}

//Typography

.title-page {
	font-family: $font-family;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: $linde-blue-100;
}

.subtitle-page {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: $linde-blue-100;
}

.field label {
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: $linde-blue-100;
}

.field-title {
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: $linde-blue-100;
	margin: 0;
}

.field-date {
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	color: $linde-blue-70;
	margin: 0;
}

.field-value {
	font-family: $font-family;
	font-style: normal;
	font-size: 14px;
	line-height: 16px;
	color: #7faac8;
	margin: 0;
	text-overflow: ellipsis;
}

.section-title {
	font-family: $font-family-medium;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
}

.hint-text {
	font-family: $font-family;
	font-style: normal;
	font-size: 12px;
	line-height: 17px;
	color: $linde-blue-100;
	margin-top: 4px;
}

//Grid

.grid-records-found {
	font-family: $font-family-medium;
	font-size: 14px;
	line-height: 39px;
	margin: 0;
}

.p-datatable .p-datatable-thead > tr > th {
	color: $linde-blue-100;
	line-height: 17px;
	font-size: 14px;
	border: none;
	border-bottom: 1px solid $linde-blue-gray-70 !important;
	font-weight: 400;
}

.p-datatable .p-datatable-tbody > tr > td:first-child,
.p-datatable .p-datatable-tbody > tr > td:last-child {
	border: none;
}

.p-datatable .p-datatable-thead > tr > th:first-child,
.p-datatable .p-datatable-thead > tr > th:last-child {
	border: none;
	border-radius: 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	border-bottom: 1px solid rgba(206, 220, 227, 0.16) !important;
	border: none;
	font-weight: 700;
	color: $linde-blue-100;
	background-color: #fff;
	font-weight: 400;
}

.p-datatable .p-sortable-column:not(.p-highlight):not([sortableBy='']):hover,
.p-datatable .p-sortable-column.p-highlight:not([sortableBy='']):hover {
	color: $linde-primary-hover;
	box-shadow: inset 0px -3px 0 0px $linde-primary-hover;
	background: white;
}

.p-datatable .p-sortable-column[sortableBy='']:hover {
	background: white;
	color: $linde-blue-100;
}

.p-datatable
	.p-sortable-column:not(.p-highlight):not([sortableBy='']):hover
	.p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:not([sortableBy='']):hover .p-sortable-column-icon {
	color: $linde-primary-hover;
}

.p-datatable .p-datatable-thead > tr > th.p-frozen-column,
.p-datatable .p-datatable-tbody > tr > td.p-frozen-column,
.p-datatable .p-sortable-column.p-frozen-column:not(.p-highlight):not([sortableBy='']):hover,
.p-datatable .p-sortable-column.p-frozen-column.p-highlight:not([sortableBy='']):hover {
	background-color: $linde-blue-gray-20;
}

.p-datatable .p-datatable-tbody > tr > td.action-column {
	text-align: center;
}

.p-frozen-column {
	z-index: 1;
}

.p-datatable .p-scroller::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.p-datatable .p-scroller::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
	background: $linde-gray-30;
	border-radius: 4px;
}

.p-datatable .p-scroller::-webkit-scrollbar-track:hover,
.scrollbar::-webkit-scrollbar-track:hover {
	background: $linde-gray-50;
}

.p-datatable .p-scroller::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
	background: $linde-blue-50;
	border-radius: 4px;
}

.p-datatable .p-scroller::-webkit-scrollbar-thumb:hover,
.scrollbar::-webkit-scrollbar-thumb:hover {
	background: $linde-blue-70;
}

ul.grid-chips {
	margin: 0;
	padding: 0.8rem;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

ul.grid-chips > li {
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
}

.style-materials {
	background-color: #beb49b;
}

.style-bulks {
	background-color: #96559b;
}

.style-gases {
	background-color: #afb478;
}

.style-packaged {
	background-color: #002d54;
}

.style-yes {
	background-color: #002d54;
	padding: 0.2rem 0.6rem !important;
}

.style-no {
	background-color: #99a0a0;
}

ul.grid-chips > li:not(:last-child) {
	margin-right: 0.5rem;
}

//Tooltip

.p-tooltip-arrow {
	display: none;
}

.button-client-data {
	padding: 0rem;

	.p-button-icon {
		font-size: 0.7rem;
		margin-left: 0.3rem;
	}
}

img.ngx-loading-logo.center-center.ng-star-inserted {
	width: 150px !important;
	height: 80px;
	top: calc((50% - 30px) - 12px);
}

.buttonCancel {
	background: $linde-blue-10;
	color: $linde-blue-100;
	border-color: $linde-blue-10;
}

.cont-main {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
}

.cont-checkbox {
	height: 224px;
	border-radius: 0px;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	transition: transform var(--transition);
	background: white;
	border: 1px solid $linde-blue-gray-70;

	&:active {
		transform: scale(0.9);
	}

	.select-prod {
		height: 100%;
	}

	input {
		display: none;

		&:checked + .select-prod {
			box-shadow: 0px 3px 18px 3px $linde-blue-50;
			border: 1px solid $linde-blue-50;
		}

		&:checked + label {
			opacity: 1;

			.cover-checkbox {
				opacity: 0;
				transform: scale(1);

				svg {
					stroke-dashoffset: 0;
				}
			}

			img {
				-webkit-filter: none;
				filter: none;
			}
		}
	}

	label {
		cursor: pointer;
		overflow: hidden;
		width: 100%;
		height: 82%;
		position: relative;
		opacity: 1;
		border-color: 1px solid red;

		img {
			width: 100%;
			height: 75%;
			object-fit: contain;
		}

		.cover-checkbox {
			position: absolute;
			right: 5px;
			top: 3px;
			z-index: 1;
			width: 23px;
			height: 23px;
			border-radius: 50%;
			background: var(--box-shadow);
			border: 2px solid #fff;
			transition: transform var(--transition), opacity calc(var(--transition) * 1.2) linear;
			opacity: 0;
			transform: scale(0);
		}

		.info {
			text-align: center;
			color: $linde-blue-100;
			font-family: $font-family;
			font-weight: 600;
			font-size: 0.8rem;
		}
	}
}

@media (max-width: 1260px) {
	.cont-checkbox {
		height: 224px;
	}
}

.link {
	cursor: pointer;
	margin-top: 4px;
}

.button-client-data {
	padding: 0rem;
}

.link {
	cursor: pointer;
}

img.ngx-loading-logo.center-center.ng-star-inserted {
	width: auto !important;
	height: 54px;
	top: calc((50% - 30px) - 12px);
}
.p-progress-spinner.loading-spinner.loading-large {
	width: 3.5rem !important;
	height: 3.5rem !important;
	top: -20px;
}

.img img {
	margin-bottom: -18px;
	object-fit: cover;
	height: 100%;
}

@media (min-width: 992px) and (max-width: 1090px) {
	.img img {
		margin-left: 10%;
	}
}

@media (min-width: 1090px) and (max-width: 1305px) {
	.img img {
		margin-left: 32%;
	}
}

@media (min-width: 1305px) and (max-width: 1340px) {
	.img img {
		margin-left: 36%;
	}
}

.p-card.request-details-card .p-card-body {
	padding: 0 1rem 0 0;
}
.p-card.patient-details-card .p-card-body {
	padding: 0px;
}
.details-calendar-icon {
	color: $linde-blue-70;
}

.select-item {
	height: 64px;
	width: 100%;
}

.select-item-seleted {
	height: 64px;
	background-color: #d2e1eb;
	width: 100%;
}

.item-container {
	margin-bottom: 15px;
	margin-top: 15px;
	margin-right: 10%;
}

.item-code {
	font-family: $font-family-medium;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 200%;
	/* identical to box height, or 16px */
	text-align: center;
	color: #ffffff;
	position: absolute;
	width: 30px;
	height: 16px;
	margin-left: 5px;
	margin-right: 0px;
	top: 21%;
}

.item-code-container {
	width: 40px;
	height: 40px;
	left: 0px;
	top: 17px;
	margin-right: 15px;
	border-radius: 50%;
	display: flex;
}

.notification-button {
	position: relative;
	width: 42px;
	top: 1px;
	right: 1px;
	overflow: visible !important;
}

.notifications-badge {
	background-color: #ffff;
	color: $linde-blue-100;
	position: absolute;
	top: 11px;
	right: 50px;
	border-radius: 10px;
	z-index: 100;
	font-size: 10px;
}

.item-label {
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: $linde-blue-100;
	margin-left: 160%;
}

.item-date {
	/* Body XS/Bold */
	font-family: $font-family-medium;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	margin-left: 22px;
	color: $linde-blue-50;
	margin-top: -9px;
	margin-bottom: -6px;
}

.notification-bull {
	color: $greenish-yellow;
	font-size: 22px;
	margin-right: 10px;
	margin-top: 1.5%;
}

.notification-bull-read {
	color: transparent;
}

.Body-M-Regular {
	/* Body M/Regular */
	font-family: $font-family-normal;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: $linde-blue-100;
	margin-top: -2px;
}

.Body-M-Regular-medium {
	/* Body M/Regular */
	font-family: $font-family-normal;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: $linde-blue-70;
	margin-top: -2px;
}

.Body-S-Bold {
	/* Body S/Bold */
	font-family: $font-family-medium;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: flex-end;
	color: $linde-blue-100;
	margin-top: -2px;
}

.Body-S-Medium {
	/* Body S/Medium */
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 17px;
	color: $linde-blue-100;
	margin-top: -2px;
}

.background-active {
	background-color: $linde-light-gray-30;
}

.background-disabled {
	background-color: transparent;
}
.access {
	margin-left: 100%;
}
.text-align-end {
	text-align: end;
}

// .p-sidebar-right {
// 	top: 5rem !important;
// 	right: 0;
// 	width: 100% !important;
// 	border-top: none;
// 	position: relative;
// 	z-index: 4;
// 	border: none;
// 	.p-sidebar-content {
// 		z-index: 5;
// 	}
// 	&::after {
// 		content: '';
// 		position: absolute;
// 		background: #ffffff;
// 		width: 100%;
// 		top: 0;
// 		height: 200%;
// 		transform: translateY(-50%);
// 		border: 1px solid rgba(0, 0, 0, 0.2);
// 		box-sizing: border-box;
// 		z-index: 3;
// 	}
// }

// Global pages views styles
.views-title {
	margin: 4px 0 12px 0;
}

.p-breadcrumb {
	padding: 0;
}

.p-scroller::-webkit-scrollbar-thumb {
	background: #7faac8;
	border-radius: 4px;
}

.p-scroller::-webkit-scrollbar-thumb:hover {
	background: #5a92bb;
}

.p-scroller::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.p-scroller::-webkit-scrollbar-track {
	background: #d3d6d6;
	border-radius: 4px;
}

@include viewport.for-desktop {
	.p-sidebar-right {
		width: 26rem !important;
	}
}

.no-text-overflow {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.wrap-anywhere {
	overflow-wrap: anywhere;
}

.wrap-anywhere-with-max {
	overflow-wrap: anywhere;
	max-width: 150px;
}

.object-fit-image {
	object-fit: contain;
}

#sidebar > #sidebar-content {
	overflow-y: visible;
}

.title-types {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	color: $linde-blue-100;
	margin: 0;
}

// STYLES SALES REQUEST
.title-sales {
	margin: 1px 0 12px 0;
	font-size: 1.4rem;
}

.disable-scroll .ui-dialog-content {
	overflow: hidden !important;
}

.item-code-letter {
	font-family: 'LindeDaxGlobal Medium', Arial;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 200%;
	/* identical to box height, or 16px */
	text-align: center;
	color: #ffffff;
	position: relative;
	width: 50px;
	height: 50px;
	margin-top: 3px;
}

.item-code-container-letter {
	width: 50px;
	height: 50px;
	left: 0px;
	top: 17px;
	margin-right: 15px;
	border-radius: 50%;
	display: flex;
}

.field-title-product {
	font-family: $font-family;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: $linde-blue-100;
	margin: 0;
}

.container-new-product {
	height: 25.4rem;
}

.item-new-product {
	border-bottom: 1px solid #d8dbdb;
	border-width: 80%;
}

.button-add-product {
	background-color: #d2e1eb;
	border-color: #d2e1eb;
	color: #005591;
}

.icon-circle {
	font-size: 10rem;
	color: #e3ebef;
	cursor: pointer;
}

.container-add-product {
	height: 60%;
}

span.p-button-icon.pi.pi-pencil {
	font-size: 13px !important;
}

span.p-button-icon.pi.pi-comment {
	font-size: 13px !important;
}

span.p-button-icon.pi.pi-trash {
	font-size: 13px !important;
}

.margin-2-code {
	margin-top: 2px !important;
}

.title-1 {
	font-size: 30px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0px;
	text-align: left;
	font-family: $font-family;
	color: $linde-blue-100;
}
