@font-face {
	font-family: 'LindeDaxGlobal';
	src: url('/assets/fonts/LINDEDAXOREG.TTF') format('truetype');
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
}

@font-face {
	font-family: 'LindeDaxGlobal';
	src: url('/assets/fonts/LINDEDAXOREG.TTF') format('truetype');
}

@font-face {
	font-family: 'LindeDaxGlobal Light';
	src: url('/assets/fonts/LINDEDAXOLIG.TTF') format('truetype');
}

@font-face {
	font-family: 'LindeDaxGlobal Medium';
	src: url('/assets/fonts/LINDEDAXOMED.TTF') format('truetype');
}
