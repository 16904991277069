@import '../variables.scss';

// -------------------------- Particulars changes --------------------------
.linde-calendar {
	.p-datepicker-month,
	.p-datepicker-year {
		font-size: 1rem;
	}
	.p-disabled,
	.p-disabled * {
		background: none !important;
		border: none !important;
		box-shadow: none !important;
		opacity: 0.5;
	}
}

.requests-filters {
	.p-button {
		min-width: 50px;
		justify-content: center;
		.p-button-label {
			flex: none;
			margin: 0 2px;
		}
	}
}

.estimated-calendar {
	.p-datepicker-today {
		:not(span.p-highlight):not(:hover) {
			background-color: inherit !important;
		}
	}
}

.product-tooltip {
	max-width: none;
	.p-tooltip-text {
		font-size: 0.9rem;
		padding: 0 33px 0 12px;
		margin-bottom: 6px;
		max-width: 500px;
		border-radius: 6px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	}

	.tooltip-header {
		cursor: pointer;
		position: absolute;
		right: 12px;
		top: 0;
	}
}

// -------------------------- End Particulars changes --------------------------

// -------------------------- Globals changes --------------------------

// Inputs number type changed styles to show only down and up icons inside the input
.p-inputnumber {
	position: relative;
}

.p-inputnumber-button-group {
	position: absolute;
	right: 0;
	height: 100%;
}

.p-inputnumber-button,
.p-inputnumber-button.p-button:enabled:hover,
.p-inputnumber-button:disabled {
	background: none !important;
	border: none !important;
	color: $linde-gray-100;
	font-size: 0.9rem;
	box-shadow: none !important;
	&.p-button:enabled:hover {
		color: $linde-blue-100;
	}
}

// Removed not ordered coumns icon.
.p-datatable .p-sortable-column .p-sortable-column-icon {
	&.pi-sort-alt {
		display: none;
	}
}

// set splitbutton dropdown z-index
.p-tieredmenu {
	z-index: 2000 !important;
	width: fit-content;
}

// Set break words for observations label fields
.field-observations {
	overflow-wrap: break-word;
}

.item-observation {
	max-width: 300px;
}

// -------------------------- End Globals changes --------------------------
