@mixin for-phone-only {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin for-phone-landscape {
  @media (max-width: 576px) and (orientation: landscape) {
    @content;
  }
}

@mixin for-tablet-mini {
  @media (min-width: 577px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for-desktop-big {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-desktop-huge {
  @media (min-width: 1800px) {
    @content;
  }
}


// Others

@mixin for-tablet-max {
  @media (max-width: 992px)  {
    @content;
  }
}
