@import 'scss-config';

$transitionDuration: 0.15s;

$font-family: 'LindeDaxGlobal';
$font-family-medium: 'LindeDaxGlobal Medium', Arial;
$font-family-normal: 'LindeDaxGlobal', Arial;

//--HEADER--//
// $headerBgColor: #FFFFFF;
$headerBgColor: linear-gradient(90.12deg, rgba(206, 220, 227, 0) 0%, #eaf0f3 100%);
$headerHeigth: 72px;
$header-z-index: 1000;

//---MENU--//
$menuBgColor: linear-gradient(90.12deg, rgba(206, 220, 227, 0) 0%, #eaf0f3 100%);
$menuWidth: 256px;
$menuHeigth: 58px;

//--CONTENT--//
$contentBgColor: #f7f7f7;
$sidePanelContentWidth: 400px;
$bdColorContend: #f5f5f5;

//--FOOTER--//
$footerBgColor: #2d2926;
//$footerBgColor: linear-gradient(90.12deg, rgba(206, 220, 227, 0) 0%, #EAF0F3 100%);
$footerHeigth: 80px;
$footerWidth: 1580px;

//COLORS
$linde-blue-100: #005591;
$linde-blue-70: #4c88b2;
$linde-blue-50: #7faac8;
$linde-blue-30: #b2ccde;
$linde-blue-10: #b3ccde;
$linde-blue-200: #002f5a;
$linde-darkblue: #002d54;

$linde-cyan: #00a0e1;
$linde-secondary: #005591;
$linde-secondary-bkgr: #d2e1eb;

$linde-light-gray-100: #d2e1eb;
$linde-light-gray-70: #dce6eb;
$linde-light-gray-50: #e8f0f5;
$linde-light-gray-30: #f1f6f9;

$linde-blue-gray-100: #b9cdd7;
$linde-blue-gray-90: #c0d2db;
$linde-blue-gray-80: #c7d7df;
$linde-blue-gray-70: #cedce3;
$linde-blue-gray-60: #d5e1e7;
$linde-blue-gray-50: #dce6eb;
$linde-blue-gray-40: #e3ebef;
$linde-blue-gray-30: #eaf0f3;
$linde-blue-gray-20: #f1f5f7;
$linde-blue-gray-10: #f8fafb;

$linde-gray-100: #6e7878;
$linde-gray-70: #99a0a0;
$linde-gray-50: #b6bbbb;
$linde-gray-30: #d3d6d6;

$linde-red-100: #e1000f;
$linde-red-70: #ea4c57;

$linde-green-100: #009b3c;
$linde-green-70: #4cb976;

$linde-primary-hover: #007ab9;
$linde-secondary-hover: #a8c5d9;
$linde-button-text: #ffffff;
$linde-button-text: #ffffff;

$greenish-yellow: #becd00;
