.custom-scroll {
	/* width */
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #7faac8;
		border-radius: 4px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #4c88b2;
	}
}

// Dropdown loader

.input-loader {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #ffffff65;
	z-index: 4;

	.loader {
		width: 24px;
		height: 24px;
		border: 3px solid $linde-blue-100;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.product-loader {
	background-color: #ffffff;

	.loader {
		width: 44px;
		height: 44px;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: #005591;
	-webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
	background-color: initial !important;
	font-size: 16px;
}
